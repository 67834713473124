<template>
  <div id="nav" class="sidenav">
    <ul>
      <li>
        <router-link to="/app/list">首页</router-link><span>
          <router-link to="/app/list">首页</router-link>
        </span>
      </li>
      <!-- 新版AI应用 -->
      <!-- <li :class="$route.path.includes('/ai')?'li-helper on':'li-helper'">
        <router-link to="/ai/list">AI助手<span class="badge pull-right" style="transform: scale(.95);right:0px;padding: 3px 4px;">new</span></router-link>
      </li> -->
      <!-- 老版AI入口 -->
      <!-- <li :class="$route.path.includes('/helper')?'li-helper on':'li-helper'">
        <router-link to="/helper/index">AI助手<span class="badge pull-right" style="transform: scale(.95);right:0px;padding: 3px 4px;">new</span></router-link>
      </li> -->
      <!-- 链接打开网址http://ai.bmob.tech -->
      <!-- <li class="li-helper">
        <a href="http://ai.bmob.tech" target="_blank">AI助手<span class="badge pull-right" style="transform: scale(.95);right:0px;padding: 3px 4px;">new</span></a>
      </li> -->

      <li :class="$route.path.includes('/app')?'on':''">
        <router-link to="/app/list">应用</router-link><span>
          <router-link to="/app/list">控制台首页</router-link>
        </span>
      </li>
      <li style="display: none;" :class="$route.path.includes('/shop')?'on':''">
        <router-link to="/shop/getBuyProject">源码</router-link>
      </li>
      <li style="display: none;" :class="$route.path.includes('/increment')?'on':''">
        <router-link to="/increment/devlist">增值</router-link>
      </li>
      <li :class="$route.path.includes('/finance')?'on':''">
        <router-link to="/finance/index">财务</router-link>
      </li>
      <li :class="$route.path.includes('/notify')?'on':''">
        <router-link to="/notify/index">消息<span v-if="unreadNum" class="badge pull-right">{{ unreadNum }}</span> </router-link>
      </li>
      <li :class="$route.path.includes('/workOrder')?'on':''">
        <router-link to="/workOrder/index">工单</router-link>
      </li>
      <li :class="$route.path.includes('/account')?'on':''">
        <router-link to="/account/index">账户</router-link>
      </li>
    </ul>
  </div>
</template>
<script>
import { getNotifyUnreadNum } from '@/api/backend'
export default {
  data() {
    return {
      unreadNum: 0
    }
  },
  mounted() {
    this.getNotify()
    this.$EventBus.$on('notifyNumChange', () => {
      this.getNotify()
    })
  },
  beforeDestroy() {
    this.$EventBus.$off('notifyNumChange')
  },
  methods: {
    getNotify() {
      getNotifyUnreadNum().then(res => {
        res = res.data
        this.unreadNum = (res[1] || 0) + (res[2] || 0) + (res[3] || 0)
      })
    }
  }
}
</script>
<style scoped src="@/styles/base1.css"/>
